import state from './moduleIntegrateState.js'
import mutations from './moduleIntegrateMutations.js'
import actions from './moduleIntegrateActions.js'
import getters from './moduleIntegrateGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}