export default {
    SET_SITES(state, sites) {
        state.sites = sites;
    },
    SET_LIST_DATE(state, list_date) {
        state.list_date = list_date;
    },
    SET_ORDERS(state, orders) {
        state.orders = orders;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
  }