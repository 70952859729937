/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";


function build_query(data) {
  var query = "";
  for(var key in data) {
    if(data[key] || data[key] === 0) {
      if(Object.keys(data).indexOf(key) == 0) {
        query += `${key}=${data[key]}`;
      } else {
        query += `&${key}=${data[key]}`;
      }
    }
  }

  return query;
}


export default {
  // eslint-disable-next-line no-unused-vars
  get_all(context) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      ApiService.get_for_2nd_service(`/autmprivate/accoutant/allpartner`)
        .then(response => {
          if(response.data.Message == "Success") {
            var sites = response.data.Data || [];
            if(sites) {
              context.commit('SET_SITES', sites);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          // toast_error(toast, e, i18n);
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  create_partner(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.post_for_2nd_service(`/autmprivate/accoutant/createpartner`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Thêm website thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  get_list_date(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get_for_2nd_service(`/autmAPI/accoutanttest/cart/listDate`)
        .then(response => {
          if(response.data.Message == "Success") {
            var list_date = response.data.Data.ListDate || [];
            if(list_date) {
              context.commit('SET_LIST_DATE', list_date);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  get_all_orders(context, payload) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      // var { pageSize, pageIndex, nameQuery, startTimeRegisted, endTimeRegisted, hadDeviceID } = payload;
      ApiService.get_for_2nd_service(`/autmAPI/accoutanttest/cart/-1?${build_query(payload)}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var orders = response.data.Data.Carts || [];
            var total = response.data.Data.Total;;
            if(orders) {
              context.commit('SET_ORDERS', orders);
              context.commit('SET_TOTAL', total);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  update_partner(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.put_for_2nd_service(`/autmprivate/accoutant/updatepartner`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Cập nhật thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

}
