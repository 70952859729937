import state from './moduleUserZaloState.js'
import mutations from './moduleUserZaloMutations.js'
import actions from './moduleUserZaloActions.js'
import getters from './moduleUserZaloGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}