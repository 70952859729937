export default {
    SET_USERS_ZALO(state, users) {
        state.users_zalo = users;
    },
    SET_TOTAL_ZALO(state, total) {
        state.total_zalo = total;
    },
    SET_USER_ZALO(state, user) {
        state.user_zalo = user;
    },
  }