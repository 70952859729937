export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_events(state) {
      return state.events;
    },
    get_event(state) {
      return state.event;
    },
    get_item: state => (id) => state.events.find((event) => event.id == id),
  }
  