const MODULE_KEY = "enabled_modules";

export const get_enabled_modules = () => {
  return window.localStorage.getItem(JSON.parse(MODULE_KEY));
};

export const save_enabled_modules = modules => {
  window.localStorage.setItem(MODULE_KEY, JSON.stringify(modules));
};

export const destroy_enabled_modules = () => {
  window.localStorage.removeItem(MODULE_KEY);
};

export const is_enabled = (_module) => {
    if(JSON.parse(window.localStorage.getItem(MODULE_KEY)).indexOf(_module) > -1) {
        return true;
    } else {
        return false;
    }
}

export default { get_enabled_modules, save_enabled_modules, destroy_enabled_modules, is_enabled };
