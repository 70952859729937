import state from './moduleLiveState.js'
import mutations from './moduleLiveMutations.js'
import actions from './moduleLiveActions.js'
import getters from './moduleLiveGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}