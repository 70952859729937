/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";

function build_query(data) {
  var query = "";
  for(var key in data) {
    if(data[key]) {
      if(Object.keys(data).indexOf(key) == 0) {
        query += `${key}=${data[key]}`;
      } else {
        query += `&${key}=${data[key]}`;
      }
    }
  }

  return query;
}

export default {

  get_live_by_id(context, payload) {
    return new Promise((resolve, reject) => {
      const id  = payload.id;
      if(!id) return;

      // const toast = payload.toast;
      ApiService.get(`/autmAPI/web/live/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var live = response.data.Data;
            if(live) {
              context.commit('SET_LIVE', live);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },
  get_all(context, payload) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      ApiService.get(`/autmAPI/web/live/-1?${build_query(payload)}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var lives = response.data.Data.Lives || [];
            if(lives) {
              context.commit('SET_LIVES', lives);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  create_live(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.post(`/autmAPI/web/live`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Thêm livestream thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

  update_live(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.put(`/autmAPI/web/live`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Sửa livestream thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
  delete_live(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast, id } = payload;
      delete payload.toast;

      ApiService.delete(`/autmAPI/web/live/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Xoá livestream thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
  update_live_detail(context, payload) {
    return new Promise((resolve) => {
      const { live_id, data } = payload;

      ApiService.put(`/autmAPI/web/livedetail/${live_id}`, {Details: data})
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(true);
            // toast_success(toast, "Sửa sản phẩm thành công");
          } else {
            // toast_error(toast, "Có lỗi xảy ra");
            resolve(false);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }
          
          // console.log(e);
          // toast_error(toast, "Có lỗi xảy ra");
          // reject(e.response);
          resolve(false);
        });
    });
  },
}
