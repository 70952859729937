export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_users(state) {
      return state.users_zalo;
    },
    get_total_users(state) {
      return state.total_zalo;
    },
    get_user(state) {
      return state.user_zalo;
    },
    get_item: state => (id) => state.users_zalo.find((user) => user.id == id),
  }
  