import state from './moduleEventState.js'
import mutations from './moduleEventMutations.js'
import actions from './moduleEventActions.js'
import getters from './moduleEventGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}