export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_tags(state) {
      return state.tags;
    },
    get_tag(state) {
      return state.tag;
    },
    get_item: state => (id) => state.tags.find((tag) => tag.id == id),
  }
  