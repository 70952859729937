/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";


export default {
  get_data(context) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      // var { pageSize, pageIndex, nameQuery, startTimeRegisted, endTimeRegisted, hadDeviceID } = payload;
      ApiService.get(`/autm/zalo/home_display`)
        .then(response => {
          if(response.data.Message == "Success") {
            var homeData = response.data.Data;
            if(homeData) {
              context.commit('SET_HOME_DATA', homeData);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  create_item(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.post(`/autm/zalo/create_slide_show_item`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Thêm item thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },


  update_data(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.put(`/autm/zalo/update_home_display`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Sửa thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
  delete_item(context, payload) {
    return new Promise((resolve) => {
      const { toast, id } = payload;
      delete payload.toast;

      ApiService.delete(`/autm/zalo/delete_slide_show_item/${id}`)
        .then(response => {
          resolve(response);
          toast_success(toast, "Xoá thành công");
        })
        .catch(e => {
          toast_error(toast, "Có lỗi xảy ra");
          resolve(e.response);
        });
    });
  },
}
