/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";

export default {

  // get_post_by_id(context, payload) {
  //   return new Promise((resolve, reject) => {
  //     const id  = payload.id;
  //     if(!id) return;

  //     // const toast = payload.toast;
  //     ApiService.get(`/autmAPI/web/news/${id}`)
  //       .then(response => {
  //         if(response.data.Message == "Success") {
  //           var post = response.data.Data;
  //           if(post) {
  //             context.commit('SET_POST', post);
  //           }
  //           resolve(response);
  //         } else {
  //           // toast_error(toast, { response }, i18n);
  //           resolve(response);
  //         }
  //       })
  //       .catch(e => {
  //         // toast_error(toast, e, i18n);
  //         reject(e.response);
  //       });
  //   });
  // },
  get_all(context) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      ApiService.get(`/autmAPI/web/pages`)
        .then(response => {
          if(response.data.Message == "Success") {
            var pages = response.data.Data.Pages || [];
            if(pages) {
              context.commit('SET_PAGES', pages);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  // create_post(context, payload) {
  //   return new Promise((resolve, reject) => {
  //     const { toast } = payload;
  //     delete payload.toast;

  //     ApiService.post(`/autmAPI/web/news`, payload)
  //       .then(response => {
  //         if(response.data.Message == "Success") {
  //           resolve(response);
  //           toast_success(toast, "Thêm bài viết thành công");
  //         } else {
  //           toast_error(toast, "Có lỗi xảy ra");
  //           resolve(response);
  //         }
  //       })
  //       .catch(e => {
  //         toast_error(toast, "Có lỗi xảy ra");
  //         reject(e.response);
  //       });
  //   });
  // },

  update_page(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.put(`/autmAPI/web/pages`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Sửa bài viết thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }
          
          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
  // delete_post(context, payload) {
  //   return new Promise((resolve, reject) => {
  //     const { toast, id } = payload;
  //     delete payload.toast;

  //     ApiService.delete(`/autmAPI/web/news/${id}`)
  //       .then(response => {
  //         if(response.data.Message == "Success") {
  //           resolve(response);
  //           toast_success(toast, "Xoá bài viết thành công");
  //         } else {
  //           toast_error(toast, "Có lỗi xảy ra");
  //           resolve(response);
  //         }
  //       })
  //       .catch(e => {
  //         toast_error(toast, "Có lỗi xảy ra");
  //         reject(e.response);
  //       });
  //   });
  // },
}
