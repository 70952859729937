import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/vue-meta";
import "@/core/plugins/vue-date-range-picker";
import "@/core/plugins/vue-date-time-picker";
import "@/core/plugins/trans_code";

import JwtService from "@/core/services/jwt.service";
import { can } from "@/core/services/permission.service";
import { is_enabled } from "@/core/services/modules.service";

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // check authenticate and permissions
  if(to.meta.auth_required) {
    if (!JwtService.getToken()) {
      router.push({ path: '/signin' })
    }
  }

  if(to.meta.permissions) {
    for(var i in to.meta.permissions) {
      if(!can(to.meta.permissions[i])) {
        router.push({ path: '/403' })
        break;
      }
    }
  }

  if(to.meta.modules) {
    for(var j in to.meta.modules) {
      if(!is_enabled(to.meta.modules[j])) {
        router.push({ path: '/501' })
        break;
      }
    }
  }

  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
