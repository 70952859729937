const PERMISSION_KEY = "permissions";

export const get_permissions = () => {
  return window.localStorage.getItem(JSON.parse(PERMISSION_KEY));
};

export const save_permissions = permissions => {
  window.localStorage.setItem(PERMISSION_KEY, JSON.stringify(permissions));
};

export const destroy_permissions = () => {
  window.localStorage.removeItem(PERMISSION_KEY);
};

export const can = (permission) => {
    if(JSON.parse(window.localStorage.getItem(PERMISSION_KEY)).indexOf(permission) > -1) {
        return true;
    } else {
        return false;
    }
}

export default { get_permissions, save_permissions, destroy_permissions, can };
