export default {
    SET_PRODUCTS(state, products) {
        state.products = products;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_PRODUCT(state, product) {
        state.product = product;
    },
  }