/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error } from "@/core/services/notify.service";

export default {

  get_roles(context, payload) {
    return new Promise((resolve, reject) => {
      const toast = payload.toast;
      const i18n  = payload.i18n;

      ApiService.get(`/api/v1/roles`)
        .then(response => {
          if(response.data.ok) {
            var roles = response.data.data;
            if(roles) {
              context.commit('SET_ROLES', roles);
            }
            resolve(response);
          } else {
            toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }
          
          toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },
}
