export default {
    SET_ORDERS(state, orders) {
        state.orders = orders;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_ORDER(state, order) {
        state.order = order;
    },
    SET_LINK_ORDERS(state, link_orders) {
        state.link_orders = link_orders;
    },
    SET_LIST_DATE(state, list_date) {
        state.list_date = list_date;
    }
  }