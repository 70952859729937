import Vue from "vue";
import Vuex from "vuex";

import auth1 from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";

import auth from "@/store/auth/moduleAuth";
import users from "@/store/admin/users/moduleUser";
import roles from "@/store/admin/roles/moduleRole";
import categories from "@/store/admin/categories/moduleCategory";
import products from "@/store/admin/products/moduleProduct";
import tags from "@/store/admin/tags/moduleTag";
import orders from "@/store/admin/orders/moduleOrder";
import lives from "@/store/admin/lives/moduleLive";
import posts from "@/store/admin/posts/modulePost";
import pages from "@/store/admin/pages/modulePage";
import settings from "@/store/admin/settings/moduleSetting";
import chat from "@/store/admin/chat/moduleChat";
import integrate from "@/store/admin/integrate/moduleIntegrate";
import promotions from "@/store/admin/promotions/modulePromotion";
import events from "@/store/admin/events/moduleEvent";
import users_zalo from "@/store/admin/users_zalo/moduleUserZalo";
import videos from "@/store/admin/videos/moduleVideo";
import home from "@/store/admin/home/moduleHome";

import uploads from "@/store/admin/uploads/moduleUpload";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    auth1,
    htmlClass,
    config,
    breadcrumbs,
    profile,

    users,
    roles,
    categories,
    products,
    tags,
    orders,
    lives,
    posts,
    pages,
    settings,
    uploads,
    chat,
    integrate,
    promotions,
    events,
    users_zalo,
    videos,
    home
  }
});
