export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_all_sites(state) {
      return state.sites;
    },
    get_list_date(state) {
      return state.list_date;
    },
    get_orders(state) {
      return state.orders;
    },
    get_total_orders(state) {
      return state.total;
    },
  }
  