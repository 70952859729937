import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "",
      redirect: "/users/index",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/users",
          name: "users",
          redirect: "/users/index",
          component: () => import("@/view/admin/users/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "users-index",
              component: () => import("@/view/admin/users/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "users-create",
              component: () => import("@/view/admin/users/create.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/categories",
          name: "categories",
          redirect: "/categories/index",
          component: () => import("@/view/admin/categories/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "categories-index",
              component: () => import("@/view/admin/categories/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "categories-create",
              component: () => import("@/view/admin/categories/create.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "categories-update",
              component: () => import("@/view/admin/categories/update.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/integrate",
          name: "integrate",
          redirect: "/integrate/index",
          component: () => import("@/view/admin/integrate/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "integrate-index",
              component: () => import("@/view/admin/integrate/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "test-data-date",
              name: "integrate-test-data-date",
              component: () => import("@/view/admin/integrate/test_data_date.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "test-data-index",
              name: "integrate-test-data-index",
              component: () => import("@/view/admin/integrate/test_data.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "guide",
              name: "integrate-guide",
              component: () => import("@/view/admin/integrate/guide.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/products",
          name: "products",
          redirect: "/products/index",
          component: () => import("@/view/admin/products/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "products-index",
              component: () => import("@/view/admin/products/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "products-create",
              component: () => import("@/view/admin/products/create.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "products-update",
              component: () => import("@/view/admin/products/update.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/tags",
          name: "tags",
          redirect: "/tags/index",
          component: () => import("@/view/admin/tags/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "tags-index",
              component: () => import("@/view/admin/tags/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "tags-create",
              component: () => import("@/view/admin/tags/create.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "tags-update",
              component: () => import("@/view/admin/tags/update.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/orders",
          name: "orders",
          redirect: "/orders/index",
          component: () => import("@/view/admin/orders/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "dates",
              name: "orders-dates",
              component: () => import("@/view/admin/orders/date.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "index",
              name: "orders-index",
              component: () => import("@/view/admin/orders/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "orders-create",
              component: () => import("@/view/admin/orders/create.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "orders-update",
              component: () => import("@/view/admin/orders/update.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "link-orders",
              name: "orders-link-orders",
              component: () => import("@/view/admin/orders/link_orders.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/lives",
          name: "lives",
          redirect: "/lives/index",
          component: () => import("@/view/admin/lives/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "lives-index",
              component: () => import("@/view/admin/lives/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "lives-create",
              component: () => import("@/view/admin/lives/create.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "lives-update",
              component: () => import("@/view/admin/lives/update.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "chat/:id",
              name: "lives-chat",
              component: () => import("@/view/admin/lives/chat.vue"),
              meta: {
                auth_required: true
              },
            }
          ]
        },
        {
          path: "/users_zalo",
          name: "users_zalo",
          redirect: "/users_zalo/index",
          component: () => import("@/view/admin/users_zalo/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "users_zalo-index",
              component: () => import("@/view/admin/users_zalo/index.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/videos",
          name: "videos",
          redirect: "/videos/index",
          component: () => import("@/view/admin/videos/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "videos-index",
              component: () => import("@/view/admin/videos/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "videos-create",
              component: () => import("@/view/admin/videos/create.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/home",
          name: "home",
          redirect: "/home/index",
          component: () => import("@/view/admin/home/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "home-index",
              component: () => import("@/view/admin/home/index.vue"),
              meta: {
                auth_required: true
              },
            }
          ]
        },
        {
          path: "/events",
          name: "events",
          redirect: "/events/index",
          component: () => import("@/view/admin/events/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "events-index",
              component: () => import("@/view/admin/events/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "events-create",
              component: () => import("@/view/admin/events/create.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "events-update",
              component: () => import("@/view/admin/events/update.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/posts",
          name: "posts",
          redirect: "/posts/index",
          component: () => import("@/view/admin/posts/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "posts-index",
              component: () => import("@/view/admin/posts/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "posts-create",
              component: () => import("@/view/admin/posts/create.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "posts-update",
              component: () => import("@/view/admin/posts/update.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/pages",
          name: "pages",
          redirect: "/pages/index",
          component: () => import("@/view/admin/pages/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "pages-index",
              component: () => import("@/view/admin/pages/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "pages-update",
              component: () => import("@/view/admin/pages/update.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/settings",
          name: "settings",
          redirect: "/settings/index",
          component: () => import("@/view/admin/settings/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "settings-index",
              component: () => import("@/view/admin/settings/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "update/:id",
              name: "settings-update",
              component: () => import("@/view/admin/settings/update.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/chat",
          name: "chat",
          redirect: "/chat/index",
          component: () => import("@/view/admin/chat/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "chat-index",
              component: () => import("@/view/admin/chat/index.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        {
          path: "/promotions",
          name: "promotions",
          redirect: "/promotions/index",
          component: () => import("@/view/admin/promotions/layout.vue"),
          meta: {
            auth_required: true
          },
          children: [
            {
              path: "index",
              name: "promotions-index",
              component: () => import("@/view/admin/promotions/index.vue"),
              meta: {
                auth_required: true
              },
            },
            {
              path: "create",
              name: "promotions-create",
              component: () => import("@/view/admin/promotions/create.vue"),
              meta: {
                auth_required: true
              },
            },
          ]
        },
        
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "",
      component: () => import("@/view/pages/auth/login_pages/Login"),
      children: [
        {
          name: "signin",
          path: "/signin",
          component: () => import("@/view/pages/auth/login_pages/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "/webview",
      name: "webview",
      redirect: "/webview/chat",
      component: () => import("@/view/admin/chat/layout.vue"),
      meta: {
        auth_required: false
      },
      children: [
        {
          path: "chat",
          name: "webview-chat",
          component: () => import("@/view/admin/chat/webview.vue"),
          meta: {
            auth_required: false
          },
        },
      ]
    },
    {
      path: "/documentation",
      name: "documentation",
      redirect: "/documentation/index",
      component: () => import("@/view/layout/Layout2.vue"),
      meta: {
        auth_required: false
      },
      children: [
        {
          path: "index",
          name: "documentation-index",
          component: () => import("@/view/admin/integrate/index.vue"),
          meta: {
            auth_required: false
          },
        },
        {
          path: "test-data-date",
          name: "documentation-test-data-date",
          component: () => import("@/view/admin/integrate/test_data_date.vue"),
          meta: {
            auth_required: false
          },
        },
        {
          path: "test-data-index",
          name: "documentation-test-data-index",
          component: () => import("@/view/admin/integrate/test_data.vue"),
          meta: {
            auth_required: false
          },
        },
        {
          path: "guide",
          name: "documentation-guide",
          component: () => import("@/view/admin/integrate/guide.vue"),
          meta: {
            auth_required: false
          },
        },
      ]
    },
    {
      path: "/invite",
      name: "invite",
      component: () => import("@/view/pages/invite/index.vue")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    },
    {
      // the 403 route, when not enough permissions
      path: "/403",
      name: "403",
      component: () => import("@/view/pages/error/403.vue")
    },
    {
      // the 501 route, when not module isn't enabled
      path: "/501",
      name: "501",
      component: () => import("@/view/pages/error/501.vue")
    }
  ]
});


// import JwtService from "@/core/services/jwt.service";
// import { can } from "@/core/services/permission.service";

// router.beforeEach((to, from, next) => {
//   if(to.meta.auth_required) {
//     if (!JwtService.getToken()) {
//       router.push({ path: '/signin' })
//     }
//   }

//   if(to.meta.permissions) {
//     var check = true;
//     for(var i in to.meta.permissions) {
//       if(!can(to.meta.permissions[i])) {
//         check = false;
//         break;
//       }
//     }

//     if(!check) {
//       router.push({ path: '/403' })
//     }
//   }

//   return next()

// });

export default router;