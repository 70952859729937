/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import axios from "axios";

export default {

  upload(context, payload) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', payload.file);
      axios.post( 'http://103.15.50.244:6954/',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function(response){
        resolve(response);
      })
      .catch(function(error){
        reject(error);
      });
    });
  },
}
