export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_orders(state) {
      return state.orders;
    },
    get_total_orders(state) {
      return state.total;
    },
    get_order(state) {
      return state.order;
    },
    get_item: state => (id) => state.orders.find((order) => order.id == id),
    get_link_orders(state) {
      return state.link_orders;
    },
    get_list_date(state) {
      return state.list_date;
    }
  }
  