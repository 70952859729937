export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_pages(state) {
      return state.pages;
    },
    get_page(state) {
      return state.page;
    },
    get_item: state => (id) => state.pages.find((page) => page.Id == id),
  }
  