
// export const toast_error = (toast, error, i18n) => {
//     var error_code = null;
//     if(error.response) {
//         if(error.response.data) {
//             if(error.response.data.error) {
//                 if(error.response.data.error.error_code) {
//                     error_code = error.response.data.error.error_code;
//                 }
//             }
//         }
//     }

//     if(error_code) {
//         var patt = new RegExp(/^400-/);
//         var test = patt.test(error_code);

//         if(test) {
//             toast.toast(`Code: ${error_code}: ${i18n.t("ERROR.MESSAGE.400")}`, {
//                 title: i18n.t("ERROR.MESSAGE.oops"),
//                 variant: "danger",
//                 solid: true
//             });
//         } else {
//             toast.toast(`${i18n.t("ERROR.MESSAGE." + error_code)}`, {
//                 title: i18n.t("ERROR.MESSAGE.oops"),
//                 variant: "danger",
//                 solid: true
//             });
//         }
//     } else {
//         toast.toast(error.message, {
//             title: i18n.t("ERROR.MESSAGE.oops"),
//             variant: "danger",
//             solid: true
//         });
//     }
// };

const data = {
    400003: "Sai tên tài khoản hoặc mật khẩu",
    50001: "Slug đã tồn tại",
}

function trans(code) {
    if(code) return data[code] || "Có lỗi xảy ra !!!";
    return "Có lỗi xảy ra !!!";
}

export const toast_error = (toast, text, code) => {
    toast.toast(code ? trans(code) : text, {
        title: "Lỗi",
        variant: "danger",
        solid: true
    });
}

export const toast_success = (toast, text) => {
    toast.toast(text, {
        title: "Thành công",
        variant: "success",
        solid: true
    });
}