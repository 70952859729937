/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

// import jwt from "../../libs/jwt";

// import router from '@/router';

import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

// const localStorageKey = 'loggedIn';
// const tokenExpiryKey = 'tokenExpiry';

// const access_token_lsk = 'access_token'; // access_token local storage key
// const logged_in_lsk = 'logged_in'; // logged_in local storage key
// const token_expire_lsk = 'token_expire'; // token_expire local storage key
// const user_data_lsk = 'user_data'; // user_data local storage key

export default {

  sign_in_jwt(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/autmAPI/account/adminlogin", payload)
        .then(response => {
          console.log('response', response.data)
          if(response.data) {
            if(response.data.Data) {
              if(response.data.Data.ErrorCode) {
                resolve(response);
              } else {
                var response_data = response.data;
                var data = {
                  user_id         : response_data.Data.UserId,
                  access_token    : response_data.Data.Token,
                };
                context.commit('SET_AUTH', data);
                resolve(response);
              }
            }
          } else {
            resolve(response);
          }
        })
        .catch(e => {
          context.commit('SET_ERROR', e);
          reject(e.response);
        });
    });
  },

    // JWT
    // login_jwt({ commit }, payload) {

    //   return new Promise((resolve,reject) => {
    //     jwt.login(payload.email, payload.password)
    //       .then(response => {
    //         if(response.ok) {
    //             var data = response.data;
    //             var access_token = response.access_token;
    //             var token_expire = response.token_expire;

    //             localStorage.setItem(logged_in_lsk, 'true');

    //             localStorage.setItem(access_token_lsk, access_token);
    //             localStorage.setItem(logged_in_lsk, 'true');
    //             localStorage.setItem(token_expire_lsk, token_expire);
    //             localStorage.setItem(user_data_lsk, JSON.stringify(data));

    //             commit("SET_BEARER", access_token);
    //             commit('UPDATE_USER_INFO', data);

    //             resolve(response);
    //         } else {
    //             reject({message: "Wrong Email or Password"})
    //         }
    //       })
    //       .catch(error => { console.log(error); reject(error) })
    //   })
    // },

    // registerUserJWT({ commit }, payload) {

    //   const { displayName, email, password, confirmPassword } = payload.userDetails

    //   return new Promise((resolve,reject) => {

    //     // Check confirm password
    //     if(password !== confirmPassword) {
    //       reject({message: "Password doesn't match. Please try again."})
    //     }

    //     jwt.registerUser(displayName, email, password)
    //       .then(response => {
    //         // Redirect User
    //         router.push(router.currentRoute.query.to || '/')

    //         // Update data in localStorage
    //         localStorage.setItem("accessToken", response.data.accessToken)
    //         commit('UPDATE_USER_INFO', response.data.userData, {root: true})

    //         resolve(response)
    //       })
    //       .catch(error => { reject(error) })
    //   })
    // },
    // fetchAccessToken() {
    //   return new Promise((resolve) => {
    //     jwt.refreshToken().then(response => { resolve(response) })
    //   })
    // }
}
