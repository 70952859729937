export default {
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_USER(state, user) {
        state.user = user;
    },
  }