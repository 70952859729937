export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_categories(state) {
      return state.categories;
    },
    get_category(state) {
      return state.category;
    },
    get_item: state => (id) => state.categories.find((category) => category.id == id),
  }
  