/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";

function build_query(data) {
  var query = "";
  for(var key in data) {
    if(data[key] || data[key] === 0) {
      if(Object.keys(data).indexOf(key) == 0) {
        query += `${key}=${data[key]}`;
      } else {
        query += `&${key}=${data[key]}`;
      }
    }
  }

  return query;
}

export default {

  get_order_by_id(context, payload) {
    return new Promise((resolve, reject) => {
      const id  = payload.id;
      if(!id) return;

      ApiService.get(`/autmAPI/web/cart/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var order = response.data.Data;
            if(order) {
              context.commit('SET_ORDER', order);
            }
            resolve(response);
          } else {
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },
  get_all(context, payload) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      // var { pageSize, pageIndex, nameQuery, startTimeRegisted, endTimeRegisted, hadDeviceID } = payload;
      ApiService.get(`/autmAPI/web/cart/-1?${build_query(payload)}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var orders = response.data.Data.Carts || [];
            var total = response.data.Data.Total;;
            if(orders) {
              context.commit('SET_ORDERS', orders);
              context.commit('SET_TOTAL', total);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  create_order(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.post(`/autmAPI/web/cart`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Thêm đơn hàng thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

  update_order(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.put(`/autmAPI/web/cart`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Sửa đơn hàng thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

  delete_order(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast, id } = payload;
      delete payload.toast;

      ApiService.delete(`/autmAPI/web/cart/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Xoá đơn hàng thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
  
  view_link_orders(context, payload) {
    return new Promise((resolve, reject) => {
      // var { date } = payload;
      ApiService.get(`/autmAPI/web/3rdGetOrderByDate?${build_query(payload)}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var link_orders = JSON.parse(response.data.Data.Data).data || [];
            
            if(link_orders) {
              context.commit('SET_LINK_ORDERS', link_orders);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // console.log(e);
          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  get_list_date(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/autmAPI/web/cart/listDate`)
        .then(response => {
          if(response.data.Message == "Success") {
            var list_date = response.data.Data.ListDate || [];
            if(list_date) {
              context.commit('SET_LIST_DATE', list_date);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },
}
