import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */

const SECOND_SERVICE = 'http://103.15.50.244:9089';
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = "http://103.15.50.244:9099";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(url, override_token) {
    if(!override_token) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`${url}`, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`${url}`, { headers: { 'Authorization': `Bearer ${override_token}` }}).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },

  get_for_2nd_service(url, override_token) {
    if(!override_token) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`${url}`, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }, baseURL: SECOND_SERVICE}).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`${url}`, { headers: { 'Authorization': `Bearer ${override_token}` }, baseURL: SECOND_SERVICE}).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(`${resource}`, params, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  post_for_2nd_service(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(`${resource}`, params, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }, baseURL: SECOND_SERVICE}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.put(`${resource}`, params, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  put_for_2nd_service(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.put(`${resource}`, params, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }, baseURL: SECOND_SERVICE}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete(`${resource}`, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }
};

export default ApiService;
