export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_settings(state) {
      return state.settings;
    },
    get_setting(state) {
      return state.setting;
    },
    get_item: state => (id) => state.settings.find((setting) => setting.Id == id),
  }
  