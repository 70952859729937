export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_posts(state) {
      return state.posts;
    },
    get_post(state) {
      return state.post;
    },
    get_item: state => (id) => state.posts.find((post) => post.id == id),
  }
  