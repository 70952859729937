/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";


export default {

  get_user_voucher(context, payload) {
    return new Promise((resolve, reject) => {
      const id  = payload.id;
      if(!id) return;
      context.commit('SET_USER_VOUCHER', []);
      // const toast = payload.toast;
      ApiService.get(`/autmAPI/promotions/user_promotions/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var data = response.data.Data || [];
            if(data) {
              context.commit('SET_USER_VOUCHER', data);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },
  reset_user_voucher(context) {
    context.commit('SET_USER_VOUCHER', []);
  },
  get_all(context, payload) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      ApiService.get(`/autmAPI/promotions/get?promo_id=-1&created_from=${payload.create_from}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var promotions = response.data.Data || [];
            if(promotions) {
              context.commit('SET_PROMOTIONS', promotions);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },

  create_promotion(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.post(`/autmAPI/promotions/create`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Thêm chương trình khuyến mại thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

  apply_promotion(context, payload) {
    return new Promise((resolve, reject) => {
      const { user_id, promotion_id, toast } = payload;
      delete payload.toast;

      ApiService.post(`/autmAPI/promotions/mark_use_voucher?user_id=${user_id}&promotion_id=${promotion_id}`, {})
        .then(response => {
          if(response.data.Message == "Success") {
            toast_success(toast, "Sử dụng voucher thành công");
            resolve(response);
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

  delete_promotion(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast, id } = payload;
      delete payload.toast;

      ApiService.delete(`/autmAPI/promotions/delete/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Xoá chương trình khuyến mại thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
}
