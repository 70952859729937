/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";

export default {

  get_category_by_id(context, payload) {
    return new Promise((resolve, reject) => {
      const id  = payload.id;
      if(!id) return;

      // const toast = payload.toast;
      ApiService.get(`/autmAPI/web/category/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var category = response.data.Data;
            if(category) {
              context.commit('SET_CATEGORY', category);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          // toast_error(toast, e, i18n);
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },
  get_all(context) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      ApiService.get(`/autmAPI/web/category/-1`)
        .then(response => {
          if(response.data.Message == "Success") {
            var categories = response.data.Data.Categories || [];
            if(categories) {
              context.commit('SET_CATEGORIES', categories);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          // toast_error(toast, e, i18n);
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },

  create_category(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.post(`/autmAPI/web/category`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Thêm nhóm sản phẩm thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },

  update_category(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.put(`/autmAPI/web/category`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Sửa nhóm sản phẩm thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
  delete_category(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast, id } = payload;
      delete payload.toast;

      ApiService.delete(`/autmAPI/web/category/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Xoá nhóm sản phẩm thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }
          
          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
}
