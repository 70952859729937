/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";

export default {
  // eslint-disable-next-line no-unused-vars
  get_list_room(context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/autmAPI/chat/listRoom`)
        .then(response => {
          if(response.data.Message == "Success") {
            console.log("fdsfdsfsdfsdfdsf", response);
            resolve(response.data.Data);
          } else {
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  get_live_room(context, payload) {
    return new Promise((resolve, reject) => {
      var { liveId } = payload;
      ApiService.get(`/autmAPI/chat/adminGetLiveRoom?liveId=${liveId}`)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response.data.Data);
          } else {
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  get_history(context, payload) {
    var { roomId, token } = payload;
    if(!token) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/autmAPI/chat/history/${roomId}?pageSize=-1&pageIndex=-1`)
          .then(response => {
            if(response.data.Message == "Success") {
              console.log("fdsfdsfsdfsdfdsf", response);
              resolve(response.data.Data);
            } else {
              resolve(response);
            }
          })
          .catch(e => {
            if(e.response.data) {
              if(e.response.data.Data) {
                if(e.response.data.Data.ErrorCode == 400002) {
                  window.location.href = '/signin';
                }
              }
            }
  
            reject(e.response);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ApiService.get(`/autmAPI/chat/history/${roomId}?pageSize=-1&pageIndex=-1`, token)
          .then(response => {
            if(response.data.Message == "Success") {
              console.log("fdsfdsfsdfsdfdsf", response);
              resolve(response.data.Data);
            } else {
              resolve(response);
            }
          })
          .catch(e => {
            if(e.response.data) {
              if(e.response.data.Data) {
                if(e.response.data.Data.ErrorCode == 400002) {
                  window.location.href = '/signin';
                }
              }
            }
  
            reject(e.response);
          });
      });
    }
  },
  // eslint-disable-next-line no-unused-vars
  request_support(context, payload) {
    var { token } = payload;
    return new Promise((resolve, reject) => {
      ApiService.get(`/autmAPI/chat/requestSupport`, token)
        .then(response => {
          if(response.data.Message == "Success") {
            console.log("fdsfdsfsdfsdfdsf", response);
            resolve(response.data.Data);
          } else {
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  get_my_info(context, payload) {
    var { token } = payload;
    return new Promise((resolve, reject) => {
      ApiService.get(`/autmAPI/account/me`, token)
        .then(response => {
          if(response.data.Message == "Success") {
            console.log("fdsfdsfsdfsdfdsf", response);
            resolve(response.data.Data);
          } else {
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  },

}
