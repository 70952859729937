export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_lives(state) {
      return state.lives;
    },
    get_live(state) {
      return state.live;
    },
    get_item: state => (id) => state.lives.find((live) => live.id == id),
  }
  