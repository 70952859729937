export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_videos(state) {
      return state.videos;
    },
    get_total_videos(state) {
      return state.total_videos;
    },
    get_video(state) {
      return state.video;
    },
    get_item: state => (id) => state.videos.find((video) => video.id == id),
  }
  