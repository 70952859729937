import Vue from "vue";

const data = {
    400003: "Sai tên tài khoản hoặc mật khẩu",
    50001: "Slug đã tồn tại",
}

const Trans = {
    install(Vue) {
        Vue.prototype.trans = (code) => {
            if(code) return data[code] || "Có lỗi xảy ra !!!";
            return "Có lỗi xảy ra !!!";
        }
    },
};
Vue.use(Trans)