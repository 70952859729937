import state from './moduleUploadState.js'
import mutations from './moduleUploadMutations.js'
import actions from './moduleUploadActions.js'
import getters from './moduleUploadGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}