export default {
    SET_VIDEOS(state, videos) {
        state.videos = videos;
    },
    SET_TOTAL_VIDEOS(state, total) {
        state.total_videos = total;
    },
    SET_VIDEO(state, video) {
        state.video = video;
    },
  }