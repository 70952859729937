export default {
    // getItem: state => (productId) => state.products.find((product) => product.id == productId),
    get_products(state) {
      return state.products;
    },
    get_total_products(state) {
      return state.total;
    },
    get_product(state) {
      return state.product;
    },
    get_item: state => (id) => state.products.find((product) => product.id == id),
  }
  