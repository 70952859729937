/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Booking-vuejs
  Author: HNA
==========================================================================================*/

import ApiService from "@/core/services/api.service";
import { toast_error, toast_success } from "@/core/services/notify.service";

function build_query(data) {
  var query = "";
  for(var key in data) {
    if(data[key]) {
      if(Object.keys(data).indexOf(key) == 0) {
        query += `${key}=${data[key]}`;
      } else {
        query += `&${key}=${data[key]}`;
      }
    }
  }

  return query;
}

export default {

  // get_user_by_id(context, payload) {
  //   return new Promise((resolve, reject) => {
  //     const id  = payload.id;
  //     if(!id) return;

  //     // const toast = payload.toast;
  //     ApiService.get(`/autmAPI/web/category/${id}`)
  //       .then(response => {
  //         if(response.data.Message == "Success") {
  //           var category = response.data.Data;
  //           if(category) {
  //             context.commit('SET_CATEGORY', category);
  //           }
  //           resolve(response);
  //         } else {
  //           // toast_error(toast, { response }, i18n);
  //           resolve(response);
  //         }
  //       })
  //       .catch(e => {
  //         // toast_error(toast, e, i18n);
  //         reject(e.response);
  //       });
  //   });
  // },
  get_all(context, payload) {
    return new Promise((resolve, reject) => {
      // const toast = payload.toast;
      // var { pageSize, pageIndex, nameQuery, startTimeRegisted, endTimeRegisted, hadDeviceID } = payload;
      ApiService.get(`/autm/zalo/video_items?${build_query(payload)}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var videos = response.data.Data.Video;
            var total = response.data.Data.Total;
            if(videos) {
              context.commit('SET_VIDEOS', videos);
              context.commit('SET_TOTAL_VIDEOS', total);
            }
            resolve(response);
          } else {
            // toast_error(toast, { response }, i18n);
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          // toast_error(toast, e, i18n);
          reject(e.response);
        });
    });
  },


  create_video(context, payload) {
    return new Promise((resolve, reject) => {
      const { toast } = payload;
      delete payload.toast;

      ApiService.post(`/autm/zalo/video_item`, payload)
        .then(response => {
          if(response.data.Message == "Success") {
            resolve(response);
            toast_success(toast, "Thêm video thành công");
          } else {
            toast_error(toast, "Có lỗi xảy ra");
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          toast_error(toast, "Có lỗi xảy ra");
          reject(e.response);
        });
    });
  },
  delete_video(context, payload) {
    return new Promise((resolve) => {
      const { toast, id } = payload;
      delete payload.toast;

      ApiService.delete(`/autm/zalo/video_item/${id}`)
        .then(response => {
          resolve(response);
          toast_success(toast, "Xoá video thành công");
        })
        .catch(e => {
          // if(e.response.data) {
          //   if(e.response.data.Data) {
          //     if(e.response.data.Data.ErrorCode == 400002) {
          //       window.location.href = '/signin';
          //     }
          //   }
          // }

          toast_error(toast, "Có lỗi xảy ra");
          // toast_success(toast, "Xoá tài khoản thành công");
          resolve(e.response);
        });
    });
  },
  get_video_by_id(context, payload) {
    return new Promise((resolve, reject) => {
      const id  = payload.id;
      if(!id) return;

      ApiService.get(`/autmAPI/web/item/${id}`)
        .then(response => {
          if(response.data.Message == "Success") {
            var product = response.data.Data;
            if(product) {
              context.commit('SET_PRODUCT', product);
            }
            resolve(response);
          } else {
            resolve(response);
          }
        })
        .catch(e => {
          if(e.response.data) {
            if(e.response.data.Data) {
              if(e.response.data.Data.ErrorCode == 400002) {
                window.location.href = '/signin';
              }
            }
          }

          reject(e.response);
        });
    });
  }
}
