// import axios from "../../libs/axios";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
    SET_BEARER(state, access_token) {
        JwtService.saveToken(access_token);
        ApiService.setHeader();
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    },
    
    SET_ERROR(state, errors) {
        state.errors = errors;
    },
    SET_AUTH(state, payload) {
        state.isAuthenticated = true;
        state.errors = {};
        JwtService.saveToken(payload.access_token);
    }
  }