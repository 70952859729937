import state from './moduleHomeState.js'
import mutations from './moduleHomeMutations.js'
import actions from './moduleHomeActions.js'
import getters from './moduleHomeGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}