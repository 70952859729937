import state from './modulePromotionState.js'
import mutations from './modulePromotionMutations.js'
import actions from './modulePromotionActions.js'
import getters from './modulePromotionGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}